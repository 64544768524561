import React from "react";
import { Helmet } from "react-helmet";
import { useStaticQuery, graphql } from "gatsby";

const ContactPageSchema = () => {
	const data = useStaticQuery(
		graphql`
			query {
				pageData: wpPage(slug: { eq: "home" }) {
					reviewCountFields {
						googleReviewCount
					}
				}
				reviews {
					reviewCount
					reviewCount2
				}
				jeffVanImg: file(relativePath: { eq: "jeff-with-van.png" }) {
					publicURL
				}
				schemaLogo: file(relativePath: { eq: "logo.png" }) {
					publicURL
				}
			}
		`
	);

	const reviewCount =
		data.reviews.reviewCount2 +
		data.pageData.reviewCountFields.googleReviewCount;

	const contactSchema = {
		"@context": "https://schema.org",
		"@type": "ContactPage",
		"@id": "https://locksmith.seymour-locksmiths.co.uk/contact-us",
		name: "Seymour Locksmiths Contact Us Page",
		description: "Get in touch with Seymour Locksmiths",
		url: "https://www.seymour-locksmiths.co.uk/contact-us",
		about: {
			"@type": "ContactPoint",
			"@id": "https://www.seymour-locksmiths.co.uk/contact-us#emergencies",
			url: "https://www.seymour-locsmiths.co.uk/contact-us#emergencies",
			image: `${data.jeffVanImg.publicURL}`,
			name: "Locksmith Emergency Line",
			description:
				"Contact our Locksmith Emergency line if you are locked out across West and East Sussex",
			telephone: "+441273465336",
			contactType: "Locksmith Emergencies",
			availableLanguage: "en",
			areaServed: [
				{
					"@type": "City",
					name: "Shoreham-by-Sea",
					sameAs: "https://en.wikipedia.org/wiki/Shoreham-by-Sea",
				},
				{
					"@type": "City",
					name: "Hove",
					sameAs: "https://en.wikipedia.org/wiki/Hove",
				},
				{
					"@type": "City",
					name: "Worthing",
					sameAs: "https://en.wikipedia.org/wiki/Worthing",
				},
				{
					"@type": "City",
					name: "Henfield",
					sameAs: "https://en.wikipedia.org/wiki/Henfield",
				},
				{
					"@type": "City",
					name: "Steyning",
					sameAs: "https://en.wikipedia.org/wiki/Steyning",
				},
				{
					"@type": "City",
					name: "Southwick",
					sameAs: "https://en.wikipedia.org/wiki/Southwick,_West_Sussex",
				},
				{
					"@type": "City",
					name: "Portslade",
					sameAs: "https://en.wikipedia.org/wiki/Portslade",
				},
				{
					"@type": "City",
					name: "Lancing",
					sameAs: "https://en.wikipedia.org/wiki/Lancing,_West_Sussex",
				},
				{
					"@type": "City",
					name: "Seaford",
					sameAs: "https://en.wikipedia.org/wiki/Seaford,_East_Sussex",
				},
				{
					"@type": "City",
					name: "Brighton",
					sameAs: "https://en.wikipedia.org/wiki/Brighton",
				},
				{
					"@type": "City",
					name: "Newhaven",
					sameAs: "https://en.wikipedia.org/wiki/Newhaven,_East_Sussex",
				},
				{
					"@type": "City",
					name: "Lewes",
					sameAs: "https://en.wikipedia.org/wiki/Lewes",
				},
				{
					"@type": "City",
					name: "Kemptown",
					sameAs: "https://en.wikipedia.org/wiki/Kemptown,_Brighton",
				},
			],

			hoursAvailable: {
				"@type": "OpeningHoursSpecification",
				"@id": "https://www.seymours-locksmiths.co.uk/contact-us/#openingHours",
				name: "Seymour Locksmiths Opening Hours",
				opens: "00:00",
				closes: "23:59",
				dayOfWeek: "http://schema.org/Friday",
				dayOfWeek: "http://schema.org/Saturday",
				dayOfWeek: "http://schema.org/Sunday",
				dayOfWeek: "http://schema.org/Monday",
				dayOfWeek: "http://schema.org/Tuesday",
				dayOfWeek: "http://schema.org/Wednesday",
				dayOfWeek: "http://schema.org/Thursday",
			},
		},
		publisher: {
			"@type": "Locksmith",
			name: "Seymour Locksmiths",
			logo: `${data.schemaLogo.publicURL}`,
			image: `${data.jeffVanImg.publicURL}`,
			telephone: "+441273465336",
			id: "https://locksmith.seymour-locksmiths.co.uk",
			description:
				"Seymour Locksmiths are a local, mobile and trusted emergency locksmith in Shoreham-by-sea serving Hove, Worthing, Portslade, Steyning, Southwick, Lancing, East Sussex and West Sussex",
			knowsAbout: "https://en.wikipedia.org/wiki/Locksmithing",
			founder: {
				"@type": "Person",
				name: "Jeffrey Seymour",
				id: "https://www.seymour-locksmiths.co.uk/who-we-are#jeffseymour",
				url: "https://www.seymour-locksmiths.co.uk/who-we-are",
				description:
					"Meet Jeff Seymour, founder and owner of Seymour Locksmiths. He has lived in Shoreham-by-Sea since 2012 and has built up a great reputation as a competent, trustworthy and reliable locksmith. Before moving to Shoreham-by-Sea, he lived in Lincolnshire and specifically decided to move to Shoreham-by-Sea to buy and live in an old houseboat which was originally built in 1876 and which took a whole summer to renovate. Having worked as a professional locksmith since 2014 means that as a customer you can benefit from Jeff’s wealth of experience, whether you find yourself locked out of your commercial or residential property, need a multipoint lock repaired or replaced or are looking to upgrade the security of your property.Jeff is committed to serving his local community, which is why he works in his home town and other communities within a 30-mile radius of Shoreham-by-Sea. In terms of professional credentials, he has completed a wide range of training with the UK Locksmith Association and keeps abreast of the latest developments in locksmithing by completing annual training courses offered by retired locksmiths. Lock picking is a fine art, and like all fine arts it needs practice and training, not only because lock technology is constantly evolving, but there are also so many different types of locks. Jeff has also had a recent basic DBS (criminal record) check, as well as being a competent, safe pair of hands for all your locksmithing requirements. For your peace of mind, a new updated certification is obtained every year. During his free time, Jeff loves nothing more than getting away from it all on his motorcycle; equipped with his tent and camping gear, he loves exploring the great British countryside.",
			},
			areaServed: [
				{
					"@type": "City",
					name: "Shoreham-by-Sea",
					sameAs: "https://en.wikipedia.org/wiki/Shoreham-by-Sea",
				},
				{
					"@type": "City",
					name: "Hove",
					sameAs: "https://en.wikipedia.org/wiki/Hove",
				},
				{
					"@type": "City",
					name: "Worthing",
					sameAs: "https://en.wikipedia.org/wiki/Worthing",
				},
				{
					"@type": "City",
					name: "Henfield",
					sameAs: "https://en.wikipedia.org/wiki/Henfield",
				},
				{
					"@type": "City",
					name: "Steyning",
					sameAs: "https://en.wikipedia.org/wiki/Steyning",
				},
				{
					"@type": "City",
					name: "Southwick",
					sameAs: "https://en.wikipedia.org/wiki/Southwick,_West_Sussex",
				},
				{
					"@type": "City",
					name: "Portslade",
					sameAs: "https://en.wikipedia.org/wiki/Portslade",
				},
				{
					"@type": "City",
					name: "Lancing",
					sameAs: "https://en.wikipedia.org/wiki/Lancing,_West_Sussex",
				},
				{
					"@type": "City",
					name: "Seaford",
					sameAs: "https://en.wikipedia.org/wiki/Seaford,_East_Sussex",
				},
				{
					"@type": "City",
					name: "Brighton",
					sameAs: "https://en.wikipedia.org/wiki/Brighton",
				},
				{
					"@type": "City",
					name: "Newhaven",
					sameAs: "https://en.wikipedia.org/wiki/Newhaven,_East_Sussex",
				},
				{
					"@type": "City",
					name: "Lewes",
					sameAs: "https://en.wikipedia.org/wiki/Lewes",
				},
				{
					"@type": "City",
					name: "Kemptown",
					sameAs: "https://en.wikipedia.org/wiki/Kemptown,_Brighton",
				},
			],

			telephone: "+441273465336",
			address: {
				"@type": "PostalAddress",
				addressLocality: "Shoreham-by-Sea",
				addressRegion: "West Sussex",
				postalCode: "BN43 5EG",
				streetAddress: "Basepoint Business Centre, Little High Street",
				addressCountry: "United Kingdom",
			},
			geo: {
				"@type": "GeoCoordinates",
				latitude: "-0.2790460715873417",
				longitude: "50.83272830758599",
			},
			openingHoursSpecification: [
				{
					"@type": "OpeningHoursSpecification",
					closes: "23:59",
					dayOfWeek: "http://schema.org/Monday",
					opens: "00:00",
				},
				{
					"@type": "OpeningHoursSpecification",
					closes: "23:59",
					dayOfWeek: "http://schema.org/Tuesday",
					opens: "00:00",
				},
				{
					"@type": "OpeningHoursSpecification",
					closes: "23:59",
					dayOfWeek: "http://schema.org/Wednesday",
					opens: "00:00",
				},
				{
					"@type": "OpeningHoursSpecification",
					closes: "23:59",
					dayOfWeek: "http://schema.org/Thursday",
					opens: "00:00",
				},
				{
					"@type": "OpeningHoursSpecification",
					closes: "23:59",
					dayOfWeek: "http://schema.org/Friday",
					opens: "00:00",
				},
				{
					"@type": "OpeningHoursSpecification",
					closes: "23:59",
					dayOfWeek: "http://schema.org/Saturday",
					opens: "00:00",
				},
				{
					"@type": "OpeningHoursSpecification",
					closes: "23:59",
					dayOfWeek: "http://schema.org/Sunday",
					opens: "00:00",
				},
			],
			founder: {
				"@type": "Person",
				name: "Jeffrey Seymour",
				id: "https://www.seymour-locksmiths.co.uk/who-we-are#jeffseymour",
				url: "https://www.seymour-locksmiths.co.uk/who-we-are",
				description:
					"Meet Jeff Seymour, founder and owner of Seymour Locksmiths. He has lived in Shoreham-by-Sea since 2012 and has built up a great reputation as a competent, trustworthy and reliable locksmith. Before moving to Shoreham-by-Sea, he lived in Lincolnshire and specifically decided to move to Shoreham-by-Sea to buy and live in an old houseboat which was originally built in 1876 and which took a whole summer to renovate. Having worked as a professional locksmith since 2014 means that as a customer you can benefit from Jeff’s wealth of experience, whether you find yourself locked out of your commercial or residential property, need a multipoint lock repaired or replaced or are looking to upgrade the security of your property.Jeff is committed to serving his local community, which is why he works in his home town and other communities within a 30-mile radius of Shoreham-by-Sea. In terms of professional credentials, he has completed a wide range of training with the UK Locksmith Association and keeps abreast of the latest developments in locksmithing by completing annual training courses offered by retired locksmiths. Lock picking is a fine art, and like all fine arts it needs practice and training, not only because lock technology is constantly evolving, but there are also so many different types of locks. Jeff has also had a recent basic DBS (criminal record) check, as well as being a competent, safe pair of hands for all your locksmithing requirements. For your peace of mind, a new updated certification is obtained every year. During his free time, Jeff loves nothing more than getting away from it all on his motorcycle; equipped with his tent and camping gear, he loves exploring the great British countryside.",
			},
			aggregateRating: {
				"@type": "AggregateRating",
				bestRating: "5",
				worstRating: "1",
				ratingValue: "4.9",
				reviewCount: `${reviewCount}`,
				url: "https://www.seymour-locksmiths.co.uk/our-locksmith-reviews",
			},
			priceRange: "$$",
		},
	};

	return (
		<Helmet>
			<script type="application/ld+json">
				{JSON.stringify(contactSchema)}
			</script>
		</Helmet>
	);
};

export default ContactPageSchema;
