import React from "react";
import { useForm, ValidationError } from "@formspree/react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";

function ContactForm({ btnText }) {
	const [state, handleSubmit] = useForm("mleovnel");
	if (state.succeeded) {
		return (window.location = "/thank-you");
	}
	return (
		<Form className="w-100" onSubmit={handleSubmit}>
			<Form.Group controlId="name" className="mb-3">
				<Form.Control name="name" placeholder="Name" type="text" />
			</Form.Group>
			<ValidationError prefix="Name" field="name" errors={state.errors} />
			<Form.Group className="mb-3" controlId="email">
				<Form.Control
					name="_replyto"
					type="email"
					placeholder="Email Address"
				/>
			</Form.Group>
			<ValidationError prefix="Email" field="email" errors={state.errors} />
			<Form.Group className="mb-3" controlId="telephone">
				<Form.Control
					type="tel"
					name="telephone"
					placeholder="Telephone Number"
				/>
			</Form.Group>

			<ValidationError
				prefix="Telephone"
				field="telephone"
				errors={state.errors}
			/>
			<Form.Group className="mb-3" controlId="message">
				<Form.Control
					name="message"
					placeholder="Message"
					as="textarea"
					rows={3}
				/>
			</Form.Group>

			<ValidationError prefix="Message" field="message" errors={state.errors} />

			{/* <div
				class="g-recaptcha"
				data-sitekey="6Lf7b1IaAAAAAGMbGQ2C6owcD3CSNajlYWuG43qW"
			></div> */}
			<Button
				size="small"
				className="btn btn-primary mt-3"
				type="submit"
				id="contact-send-btn"
				disabled={state.submitting}
			>
				{btnText ?? "Send message"}
			</Button>
		</Form>
	);
}
export default ContactForm;
